import React from 'react'
import './landing.scss'
// eslint-disable-next-line
import Lottie  from 'lottie-react'
// eslint-disable-next-line
import flickBanner from '../../assets/flickBanner.json';
// eslint-disable-next-line
import HeroSpinner from '../../assets/HeroSpinner.json';
function LandingHero() {
  return (
    <div className='landing-hero'>
      <div className='landing-wrapper'>
        <div className='hero-left'>  
            {/*  eslint-disable-next-line */}
            <p className='hero-left-top'>NeoFinance working capital product is live! 🚀 </p>
            <h3 className='hero-left-text'>
            Grow your <span>business</span> on your own terms.
            </h3>
            <p className='desc-landing'>
            Apply for a NeoFinance loan to help with expenses like payroll, buying inventory, purchasing raw <br/>materials, maintenance, and more. Our seamless and transparent loan services make financial <br/> growth accessible to anyone.
            </p>
            <div className='hero-btn'>
            <a href="https://neofinance.ltd" className='btn-landing'>
            Apply now <img className='arrow-btn'  src="/images/arrow.svg" alt="arrow"/>
            </a>
            </div>
        </div>
        
      </div>
    </div>
  )
}

export default LandingHero

//https://login.merchant.getflick.co/signUp