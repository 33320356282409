import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";
import Landing from "./pages/landing/Landing";
import MerchantLanding from "./pages/merchantLanding/MerchantLanding";


function App() {
  return (
    <Router>
      <ScrollToTop >
        <Routes>
          <Route path="/" element={<MerchantLanding />} />  
          <Route path="/users" element={<Landing />} />    
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
