import React from 'react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../../components/footer/Footer';
import Header from '../../components/merchantLanding/header/Header';
import LandingHero from '../../components/merchantLanding/LandingHero';
import Main from '../../components/merchantLanding/Main';
import './merchantLanding.scss'
function MerchantLanding() {
  
  return (
    <div className='landing_page'>
      <Header />
      <LandingHero/>
      <Main />
      <Footer />
      <ToastContainer />
    </div>
  )
}

export default MerchantLanding;
