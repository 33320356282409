import React from 'react'
import { Link } from 'react-router-dom';
import './landing.scss'
import BenefitsData from './header/LoanBenefits.json'
import ProductsData from './header/LoanProducts.json'

function Main() {
   const allBenefitsData = BenefitsData;
   const allProductsData = ProductsData;
  
  return (
    <div className='landing_main'>
        <div className='main_wrapper'>
            <div className='flick-product-content'>
            <img src={process.env.PUBLIC_URL + "/images/business-img.svg"} alt="img" />
            </div>
            {/*  */}
        </div>
        {/*  */}
        <div className='companies'>
            <p className='companies-text'>Join 4,000+ companies already growing</p>
            <div className='companies-logos'>
            <img src={process.env.PUBLIC_URL + "/images/layers.svg"} alt="img" />
            <img src={process.env.PUBLIC_URL + "/images/FlickLogo.svg"} alt="img" />
            <img src={process.env.PUBLIC_URL + "/images/feather-dev.svg"} alt="img" />
            <img src={process.env.PUBLIC_URL + "/images/swift.svg"} alt="img" />
            <img src={process.env.PUBLIC_URL + "/images/global-bank.svg"} alt="img" />
            <img src={process.env.PUBLIC_URL + "/images/teller.svg"} alt="img" />
            </div>
        </div>
        {/*  */}
        <div className='why-flick-loan'>
            <div className='why-fl'>
                <p className='flick-loan'>Why Flick loan?</p>
                <p className='credit-your'>The credit your business deserves.</p>
                <p className='loan-services'>Our seamless and transparent loan services make financial growth accessible to every business.</p>
            </div>
            <div className='benefits'>
            {allBenefitsData.map((benefit, index) => (
                <div className='benefitss' key={index}>
                    <img src={process.env.PUBLIC_URL + benefit.icon} alt="img" />
                    <p className='benefit-title'>{benefit.title}</p>
                    <p className='benefit'>{benefit.benefit}</p>
                </div>
                ))}
            </div>
        </div>
        {/* */}
        <div className='why-flick-loan'>
            <div className='why-fl'>
                <p className='flick-loan'>Products</p>
                <p className='credit-your'>Explore our loan products</p>
                <p className='loan-services' style={{width:"57%"}}>Whether you're a startup seeking initial funding or an established enterprise aiming to expand, our flexible financial solutions are tailored just for you.</p>
            </div>
            <div className='loan-product'>
                <img src={process.env.PUBLIC_URL + "/images/lady.svg"} alt="img" />
                <div className='lo-product'>
                {allProductsData.map((product, index) => (
                    <div className='product-loan' key={index}>
                        <img src={process.env.PUBLIC_URL + product.icon} alt="img" />
                        <div className='product-desc'>
                            <p className='product-title'>{product.title}</p>
                            <p className='product-pro'>{product.product}</p>
                            <Link to={product.link} className='learn-more'> Learn more <img style={{display:"inline"}} src={process.env.PUBLIC_URL+"/images/coloredArrowForward.svg"} alt='arrow'/></Link>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </div>

        {/*  */}

        <div className='start_building'>
            <div className='avater_content'>
                <h1 className='avater_title'>Still thinking about it?</h1>
                <p className='avater_desc'>A member of our team will assist you in finding the ideal loan tailored to your needs</p>
                <div className='enter-email'>
                    <input type="text" className="input-email" name="email" placeholder="Enter your email"/>
                    <a style={{cursor:"pointer"}} href='https://neofinance.ltd' className='avater_btn'> Apply now <span><img className='arrow-btn'  src="/images/coloredArrowForward.svg" alt="arrow"/></span></a>
                </div>
                {/* <p className='we-care'>We care about your data in our <span style={{textDecoration:"underline"}}>privacy policy.</span></p> */}
            </div>
        </div>
       
    </div>
  )
}

export default Main
