import React from 'react';
import { Link } from 'react-router-dom';

import './Header.scss'



const Navbar = () => {
   
    return (
        <nav className="mainmenu-nav">
            <div className="mainmenu">    
                <ul className='main_menu_nav'>
                    <li>
                        <Link 
                    to=""
                        
                        >
                            About Us
                        </Link>
                    </li>
                    <li className="menu-item-has-children">
                    <Link to="#">Product</Link>
                    </li> 
                    <li>
                        <Link to="#">Pricing</Link>
                    </li>
                    

                </ul>  
                
                <div className='menu-item-btns'>       
                        <a href="https://neofinance.ltd" className='nav-signIn'>Sign In</a>
                        <div className='sign-in-line'></div>     
                        <a href="https://neofinance.ltd"   className='btn-landing'>Get started</a>
                </div>
                
            </div>
            
        </nav>
        
    )
}

export default Navbar;