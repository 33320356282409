import React from 'react'
import '../animate.scss';
function LandingContentRight({image,title,description}) {
  return (
    <div className='landingContent'> 
        
            <img src={process.env.PUBLIC_URL + image} alt='landing' />
            <div className='landingContent-desc'>
                <h1>{title}</h1>
                <p>{description}</p>
            </div>

        
    </div>
  )
}

export default LandingContentRight