import React from 'react'
import './Landing.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component'
function HeroLanding() {
  return (
    <div className='hero-landing'>
        <div className='hero-landing-container'>
            <div className='hero-content'>
                <h1>Payment has never been this <span className='easy-bg'>easy</span></h1>
                <p>One secure platform to pay, get paid, and manage all your finances better!</p>
                <div className='landing-button'>
                    {/* <a href='https://flickuser.page.link/userinvite' className="axil-btn btn-fill-primary">Join as a user</a> */}
                    {/* <a href='https://getflick.app/scan-download' className="axil-btn btn-fill-primary">Join as a user</a> */}
                    {/* <a href='/scan-download' className="axil-btn btn-fill-primary">Join as a user</a> */}
                    <a href='https://onelink.to/zzh6zq' className="axil-btn btn-fill-primary">Join as a user</a>
                    <a href='https://onelink.to/sa33jx' className="btn-no-fill">Register as a business</a>
                </div>
            </div>
            <div className='landing-img'>
                {/* <img src={process.env.PUBLIC_URL + '/images/landingHero.svg'} alt="landing-img"/> */}
                <LazyLoadImage
                src={process.env.PUBLIC_URL + '/images/landingHero.svg'}
                height={507}
                width={508}
                effect='blur'
                placeholderSrc='landing-img'
                />
            </div>
        </div>
    </div>
  )
}

export default HeroLanding