import React from 'react'
import LandingMain from '../../components/landing/LandingMain'
import Footer from '../../components/footer/Footer'
import Header from '../../components/merchantLanding/header/Header'

function Landing() {
  return (
    <div className='landing'>
        <Header />
        <LandingMain />
        <Footer />
    </div>
  )
}
export default Landing