import React from 'react'
import HeroLanding from './HeroLanding'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import './Landing.scss'
import '../animate.scss'
import LandingContent from './LandingContent'
import LandingContentRight from './LandingContentRight'

function LandingMain() {
  return (
    <div className='landing-main'>
        
          <HeroLanding />
        <div className='landing-security'>
          <AnimationOnScroll animateIn="fadeInUp" duration={2} delay={400} animateOnce={true}> 
                <div className='security-container'>
                    <img className='security-img' src={process.env.PUBLIC_URL + "/images/securityLanding.svg"} alt="security"/>
                    <div className='security-content'>
                        <h1>Security & support you can trust</h1>
                        <p>Flick uses secure processes to protect your information and help prevent unauthorized use. Also, If you need help, Flick’s support channels are on standby 24/7.</p>
                    </div>
                </div>
          </AnimationOnScroll>
        </div>
        <div className='main-content'>
            <div className='main-content-left'>
                    <LandingContent
                    image={process.env.PUBLIC_URL+"/images/landMultiple.svg"}
                    title="No need for multiple apps"
                    description="Easily connect all your accounts. From cash and credit to loans and investments, you can see your complete financial picture on Flick."
                    />
                    <LandingContent
                    image={process.env.PUBLIC_URL+"/images/landOverview.svg"}
                    title="Your Financial Overview at a glance"
                    description="From the big picture to the tiny details, Flick gives you the full overview of all your finances. Discover where your money really goes each month thanks to powerful insights about your spending habits."
                    />
                
            </div>
            <div className='main-content-right'>
                    <LandingContentRight
                    image={process.env.PUBLIC_URL+"/images/landFaster.svg"}
                    title="Pay anyone 7x faster, fee-free"
                    description="Pay friends, businesses and family fee-free, no matter what bank account they use. They get credited instantly and securely, no sign-up needed."
                    />
                    <LandingContentRight
                    image={process.env.PUBLIC_URL+"/images/landLoyalty.svg"}
                    title="Access digital loyalty cards and earn customer points"
                    description="Get rewarded by your favorite merchant with digital loyalty cards and earn customer points to shop more. With our app, no more unused/untraceable loyalty customer points."
                    />   
            </div>   
        </div>
        {/* showcase */}

        <div className='landing-showcase'>
            <div className='landing-showcase-container'>
              <div className='landing-showcase-content'>
              <AnimationOnScroll animateIn="slideInLeft" duration={2} delay={300} animateOnce={true}> 
                <h1>Start making confident <span className='money-text'>money</span> moves today</h1>
                <img src={process.env.PUBLIC_URL + "/images/moneyCircle.svg"} className='money-ribbon' alt='landing'/>
                <div className='landing-showcase-img'>
                  <img src={process.env.PUBLIC_URL + '/images/googlePlay.svg'} alt="download"/>
                  <img src={process.env.PUBLIC_URL + '/images/appStore.svg'} alt="download"/>
                </div>
              </AnimationOnScroll>
              </div>
              <AnimationOnScroll animateIn="slideInRight" duration={2} delay={300} animateOnce={true}> 
                <img src={process.env.PUBLIC_URL + "/images/showCaseImage.svg"} alt="showCase" className='showcase-img' />
              </AnimationOnScroll>
            </div>    
        </div>
        
    </div>
  )
}

export default LandingMain

