import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.scss'

function Footer() {
  return (
    <div className='footer'>
            <div className='footer-main'>
                <div className='footer-flick'>
                    <img src={process.env.PUBLIC_URL + '/images/neo-logo.svg'} alt='footer' className='footer-flick-img'/>
                    <p className='footer_secure'>Grow your business, on your own terms.</p>
                    <div className='footer-download'>
                        <a href='https://neofinance.ltd'>
                            <img src={process.env.PUBLIC_URL + "/images/get-started-now.svg"} alt='googlePlay' className='download-link'/>
                        </a>
                        
                    </div>
                </div>

                <div className='footer-widget'>
                    <h1 className='widget-title'>Company</h1>
                    <div className='footer-menu-link'>
                        <ul className="list-unstyled">
                            <li><Link to={process.env.PUBLIC_URL + ""}>About us</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + ""}>FAQs</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + ""}>Privacy policy</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + ""}>Terms & conditions</Link></li>
                        </ul> 
                    </div>   
                </div>

                <div className='footer-widget'>
                    <h1 className='widget-title'>Address</h1>
                    <div className='address'>
                        <img src={process.env.PUBLIC_URL + "/images/NgFlag.svg"}  alt="flag"/>
                        <p>6th Floor, Landmark Towers, <br/>5B Water Corporation Road, <br/>VI, Lagos</p>
                    </div>
                </div>
            </div>

            {/*  */}

            <div className='footer-line'></div>

            <div className='footer-aside'>
                <h6 className='copywrite'>All rights reserved ©2023 NeoFinance is a State Licensed Money lender with License number 0001905 authorised and regulated by the Money Lenders Act</h6>
                <div className='footer-action'>
                    <div className='foot-action-btn'>
                        <Link className='unsubscribe' to="">Unsubscribe</Link>
                        <Link className='delete'  to="">Delete profile</Link> 
                    </div>
                    <ul className='list-unstyled footer-social-link'>
                        <li><a href="https://neofinance.ltd"><img src={process.env.PUBLIC_URL + "images/facebook.svg"} alt="fb"/></a></li>
                        <li><a href="https://neofinance.ltd"><img src={process.env.PUBLIC_URL + "images/linkedin.svg"} alt="fb"/></a></li>
                        <li><a href="https://neofinance.ltd"><img src={process.env.PUBLIC_URL + "images/twitter.svg"} alt="fb"/></a></li>
                        <li><a href="https://neofinance.ltd"><img src={process.env.PUBLIC_URL + "images/ig.svg"} alt="instagram"/></a></li>
                    </ul>            
                </div>
            </div>
        
    </div>
  )
}

export default Footer